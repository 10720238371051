<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {billingService} from "@/helpers/fakebackend/billing.service";
import {ToCSV} from "@/helpers/ToCSV";
import {requestMessage} from "@/helpers/request-message";
/**
 * Form-element component
 */
export default {
  page: {
    title: "Rechnungen",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Rechnungen",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Rechnungen",
          active: true,
        },
      ],
      billings: [],
      filteredBillings: [],
      billing: {},
      tableTitle: "Rechnungen",
      tableItems: [
        {
          text: "Tables",
          href: "/"
        },
        {
          text: "Advanced",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 200, 1000],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "createDate", label: "Datum", sortable: true },
        { key: "number", label: "Rechnungsnummer", sortable: true },
        { key: "netTotal", label: "Betrag", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "buttons", label: "als PDF herunterladen"},
      ],
      globalMessage: {message: "", type: "danger"},
      isLoading: true,
    };
  },
  created: function () {
  },
  computed: {
    rows() {
      return this.billings.length;
    }
  },
  mounted() {
    const customer = JSON.parse(localStorage.getItem("customer_infos"));
    if(customer && customer.status !== 1){
      this.billings = [];
      this.isLoading = false;
    }else {
      billingService.getAll().then(data => {
        this.billings = data.content;
        this.filteredBillings = data.content;
        this.isLoading = false;

      }).catch(data => {
        this.globalMessage.message = requestMessage[data.code].de ?? "Ein Fehler ist aufgetreten";
        this.globalMessage.type = "danger";
        this.isLoading = false;
      });
    }
    this.totalRows = this.items.length;
    this.reduceColumnForSmallScreen(600);
  },
  methods: {

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.filteredBillings = filteredItems;
    },

    downloadBilling(e, billing){
      e.preventDefault();
      let self = this;
      this.globalMessage.message = "";
      billing.downloading = true;
      this.$refs.table.refresh();

      billingService.getBillingPDF(billing.number).then(data => {
        billing.downloading = false;
        self.$refs.table.refresh();
        this.showBilling(data.content.base64_content, billing);
        billing.pdf = 'data:application/pdf;base64,' + data.content.base64_content;
      }).catch(data => {
        billing.downloading = false;
        self.$refs.table.refresh();
        self.globalMessage.message = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
        self.globalMessage.type = "danger";
      });
    },
    showBilling(base64_content, billing){
      const string = 'data:application/pdf;base64,' + base64_content;
      const iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
      let x = window.open();
      x.document.open();
      x.document.write('<title>Rechnung Nr.' + billing.number + ' vom ' + this.formatDate(billing.createDate) +'</title>');
      x.document.write(iframe);
      x.document.close();
    },
    formatDate(date){
      date = date.trim().split("-");
      return date.length > 1 ? date[2] + "." + date[1] + "." + date[0] : date[0];
    },
    reduceColumnForSmallScreen(minSize){
      if (screen.availWidth <= minSize){
        this.fields = [
          { key: "number", label: "Nummer", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "buttons", label: "Aktionen"},
        ]
      }
    },
    exportTableAsCSV(){
      let csv = new ToCSV(this.filteredBillings);
      csv.createCSVFile();
    },
    formatPrice(price){
      price = ""+price;
      price = price.split(".");
      if(price.length === 1)
        return price[0] + ",00";
      else{
        if (price[1].length === 1)
          return price[0] + "," + price[1] + "0";
        else
          return price[0] + "," + price[1].slice(0,2)
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert show :variant="globalMessage.type" v-if="globalMessage.message">{{ globalMessage.message }}</b-alert>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body pl-0 ml-1rem">
              <div class="row no-gutters">
                <h2 class="mb-4">Rechnungen</h2>
              </div>
              <div class="row no-gutters">
                <div class="col-sm-12 col-md-6 pl-0">
                  <div id="tickets-table_length" class="dataTables_length">
                    <span>Anzeigen von</span>
                    <label class="d-inline-flex align-items-center">
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                    </label>
                    <span>pro Seite</span>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      <i class="ri-search-2-line"></i>
                      <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Suche"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                    <a  href="#" @click="exportTableAsCSV"
                        class="ml-2" v-b-tooltip.hover
                        title="Exportieren als CSV">
                      <i class="ri-share-box-line"></i>
                    </a>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="row no-gutters">
                <div class="col-md-12 pl-0">
                  <div class="table-responsive mb-0">
                    <b-table
                        :items="billings"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        show-empty
                        @filtered="onFiltered"
                        ref="table"
                        class="table-hover"
                    >
                      <template #cell(netTotal)="row">
                        {{ formatPrice(row.item.netTotal) }} Euro
                      </template>
                      <template #cell(status)="row">
                        <span v-if="row.item.status === 0">offen</span>
                        <span v-if="row.item.status === 1">bezahlt</span>
                        <span v-if="row.item.status === 2">storno</span>
                      </template>
                      <template #cell(buttons)="row">
                        <a size="sm" href="#" @ v-on:click="(e)=>downloadBilling(e, row.item)" title="Rechnung herunterladen">
                          <i class="ri-file-list-line" style="font-size: 1.4rem" v-if="!row.item.downloading"></i>
                          <i class="ri-loader-line" style="font-size: 1.4rem" v-else></i>
                        </a>
                      </template>
                      <template #empty>
                        <p class="text-center">Keine Rechnungen vorhanden</p>
                      </template>
                      <template #emptyfiltered>
                        <p class="text-center">Es gibt keine Einträge zu Ihrer Anfrage</p>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

  </Layout>
</template>
